class Main {
  constructor() {
    this.hamburger = document.querySelector(".hamburger");
    this.hamburger.addEventListener("click", (e) => {
      e.currentTarget.classList.toggle("open");
      document.querySelector("#kolombor-header").classList.toggle("open");
    });

    const feedbackButton = document.querySelector(".feedback");
    if (feedbackButton) {
      const contactForm = new ContactForm();
      feedbackButton.addEventListener("click", (e) => {
        contactForm.open();
      });
    }
  }
}

class ContactForm {
  constructor() {
    console.log("CONTACT FORM");
    this.formContainer = document.querySelector(".contact-form__container");
    this.form = this.formContainer.querySelector(".contact-form");
    this.inputfields = this.form.querySelector(".inputfields");

    this.closeButton = this.formContainer.querySelector(".btn-close");
    this.submitButton = this.form.querySelector(".btn-submit");
    this.message = this.form.querySelector(".message");

    this.closeButton.addEventListener("click", this);
    this.form.addEventListener("submit", this);
    console.log("CONTACT FORM __");
  }

  handleEvent(e) {
    switch (e.type) {
      case "click":
        this.close(e);
        break;
      case "submit":
        this.send(e);
        break;
    }
  }

  close(e) {
    this.formContainer.classList.remove("open");
    e.preventDefault();
  }

  open() {
    this.formContainer.classList.add("open");
    this.inputfields.classList.remove("disabled");
    this.message.classList.remove("successful");
    this.message.textContent = "";
    this.form.reset();
  }

  send(e) {
    console.log("SEND");
    const formData = new FormData(this.form);
    this.submitButton.classList.add("loading");
    fetch("/api/feedback", {
      method: "POST",
      headers: {
        "X-API-KEY": "6oyBa8VUFmeQgcrNEzlcI"
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.error) throw Error(result.error);
        this.message.textContent = "Ваше сообщение отправлено";
        this.message.classList.add("successful");
        this.submitButton.classList.remove("loading");
        this.inputfields.classList.add("disabled");
        setTimeout(() => this.close(), 3000);
      })
      .catch((error) => {
        console.log(error);
        this.message.textContent = error.message;
        this.submitButton.classList.remove("loading");
      });

    e.preventDefault();
  }
}

console.log("MAIN");
addEventListener("load", () => new Main());
